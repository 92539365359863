import { Webapps } from '@bs/models/cms/webapps';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import packageInfo  from 'package.json';

export const environment: EnvironmentConfig = {
  api: {
    accounts: 'https://api-accounts.betstarters.cloud',
    catalogs: 'https://api-catalogs.betstarters.cloud',
    cms: 'https://api-cms.betstarters.cloud/cms',
    games: 'https://api-games.betstarters.cloud',
    identity: 'https://api-identity.betstarters.cloud',
    promo: 'https://api-promo.betstarters.cloud',
    reports: 'https://api-reports.betstarters.cloud',
    sportsbook: 'https://api-sportsbook.betstarters.cloud',
  },
  appVersion: packageInfo.version,
  reportServerEndpoint: 'https://sql-reporting.bet-starters.com/ReportServer',
  webAppId: Webapps.winchester,
  bookmakerId: 4,
  production: true,
  defaultLangCode: 'en',
  langs: [
    {
      code: 'en',
      name: 'English'
    }
  ],
  theme: {
    defaultDark: true
  },
  features: {},
  homePage: 'login',
  timeZone: 'Europe/Malta'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
